import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyBKbVVSby6kjPYR1MT5jMQIHIV8ztwP95U",
//   authDomain: "tuition-finder-12f9b.firebaseapp.com",
//   projectId: "tuition-finder-12f9b",
//   storageBucket: "tuition-finder-12f9b.appspot.com",
//   messagingSenderId: "30981357638",
//   appId: "1:30981357638:web:4097ff23eb041d448f0a47"
// };
const firebaseConfig = {
  apiKey: "AIzaSyBJOBBQcG7X9SjVQwbE6zVq5fDcrvCTSt0",
  authDomain: "bd-teachers.firebaseapp.com",
  projectId: "bd-teachers",
  storageBucket: "bd-teachers.appspot.com",
  messagingSenderId: "109323759780",
  appId: "1:109323759780:web:98a894c315fd8f62cf7631",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const storage = firebase.storage();

export { auth, storage, firebaseApp };
