import { Button, Card, Grid, Snackbar } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Navigation from '../Navigation/Navigation';
import './TuitionJob.css';
import { UserContext } from '../Context/Sign_In_Context';
import { Link } from 'react-router-dom';
import { JobsContext } from '../Context/Jobs_Context';
import ApplyNowBtn from '../Apply-Now-Btn/Apply-now-btn';
import { Alert } from '@material-ui/lab';

const TuitionJob = () => {
  const [user] = useContext(UserContext);
  const [jobs] = useContext(JobsContext);
  const [arr, setArr] = useState([]);
  const [snack, setSnack] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target[0].value !== 'hide') {
      const newArr = jobs.filter((x) => x.subject.toLowerCase().includes(e.target[0].value));
      setArr(newArr);
    }
    if (e.target[1].value !== 'hide') {
      const newArr = jobs.filter((x) => x.medium === e.target[1].value);
      setArr(newArr);
    }
    if (e.target[2].value !== 'hide') {
      const newArr = jobs.filter((x) => x.class === e.target[2].value);
      setArr(newArr);
    }
    if (e.target[3].value !== 'hide') {
      const newArr = jobs.filter((x) => x.location_1.toLowerCase().includes(e.target[3].value));
      setArr(newArr);
    }
  };
  useEffect(() => {
    setArr(jobs);
  }, [jobs]);

  useEffect(() => {
    document.querySelector('body').classList.remove('no__scroll');
  }, []);

  function handleSnackMessage(msg) {
    setSnack(msg);
  }
  function handleSnackClose() {
    setSnack(null);
  }

  return (
    <div className="tuition_job tuitionBox">
      <Navigation></Navigation>
      <br></br>
      <br></br>
      <div className="filter">
        <form onSubmit={handleChange}>
          <select name="subject">
            <option value="hide">-- Subject --</option>
            <option value="science">science</option>
            <option value="arts">Arts</option>
            <option value="commerce">Commerce</option>
            <option value="math">Math</option>
            <option value="physics">Physics</option>
            <option value="chemistry">Chemistry</option>
            <option value="english">English</option>
            <option value="accounting">Accounting</option>
            <option value="finance">Finance</option>
            <option value="geology">Geology</option>
            <option value="bangla">Bangla</option>
          </select>
          <select name="medium">
            <option value="hide">-- Medium --</option>
            <option value="Bangla">Bangla</option>
            <option value="English">English</option>
            <option value="hide">Both</option>
          </select>
          <select name="prefer_class">
            <option value="hide">-- Student Class --</option>
            <option value="Any">Any Class</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <select name="subcity">
            <option value="hide">-- Subcity --</option>
            <option value="azimpur">Azimpur</option>
            <option value="badda">Badda</option>
            <option value="baily_road">Baily Road</option>
            <option value="banani">Banani</option>
            <option value="baridhara">Baridhara</option>
            <option value="chawkbazar">Chawkbazar</option>
            <option value="demra">Demra</option>
            <option value="dhanmondi">Dhanmondi</option>
            <option value="farmgate">Farmgate</option>
            <option value="gulshan">Gulshan</option>
            <option value="jatrabari">Jatrabari</option>
            <option value="khilgaon">Khilgaon</option>
            <option value="lakkhibazar">Lakkhibazar</option>
            <option value="mirpur">Mirpur</option>
            <option value="motijheel">Motijheel</option>
            <option value="mohammadpur">Mohammadpur</option>
            <option value="paltan">Paltan</option>
            <option value="puran dhaka">Puran dhaka</option>
            <option value="rampura">Rampura</option>
            <option value="shantinagar">Shantinagar</option>
            <option value="shahbagh">Shahbagh</option>
            <option value="shyamoli">Shyamoli</option>
            <option value="sutrapur">Sutrapur</option>
            <option value="tejgaon">Tejgaon</option>
            <option value="uttara">Uttara</option>
            <option value="lalbagh">Lalbagh</option>
            <option value="bongshal">Bongshal</option>
          </select>

          <button type="submit">Filter</button>
        </form>
      </div>
      <Grid container>
        {arr.map((x, i) =>
          !x.isApproved ? null : (
            <Grid key={i} item sm={12} md={6} lg={4}>
              <Card className="premium-card">
                {/* Header */}
                <div className="premium-card-header">
                  <small>Tuition ID: #{x._id}</small>
                  <h3>{x.title}</h3>
                </div>

                {/* Card Content */}
                <div className="premium-card-body">
                  <div className="premium-card-detail">
                    <div className="detail-icon">
                      <i className="fas fa-school"></i>
                    </div>
                    <p>Class: <strong>{x.class}</strong></p>
                  </div>

                  <div className="premium-card-detail">
                    <div className="detail-icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <p>Location: <strong>{x.location_1}</strong></p>
                  </div>

                  <div className="premium-card-detail">
                    <div className="detail-icon">
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                    <p>Days per Week: <strong>{x.days_per_week}</strong></p>
                  </div>

                  <div className="premium-card-detail">
                    <div className="detail-icon">
                      <i className="fas fa-book"></i>
                    </div>
                    <p>Subject: <strong>{x.subject}</strong></p>
                  </div>

                  <div className="premium-card-detail">
                    <div className="detail-icon">
                      <i className="fas fa-money-bill-wave"></i>
                    </div>
                    <p>Salary: <strong>{x.salary} Tk/Month</strong></p>
                  </div>

                  {x.extra_info && (
                    <div className="premium-card-extra">
                      <h4>Extra Info</h4>
                      <p>{x.extra_info}</p>
                    </div>
                  )}
                </div>

                {/* Footer with Button */}
                <div className="premium-card-footer">
                  <ApplyNowBtn
                    tuition={x}
                    handleSnackMessage={handleSnackMessage}
                    userEmail={user ? user.email : null}
                  />
                </div>
              </Card>
            </Grid>
          )
        )}
      </Grid>

      <Snackbar open={snack} autoHideDuration={4000} onClose={handleSnackClose}>
        <Alert severity="success" onClose={handleSnackClose}>
          {snack}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TuitionJob;
